@font-face {
    font-family: 'Source Sans 3';
    src: url('SourceSans3-Italic.woff2') format('woff2'),
        url('SourceSans3-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('SourceSans3-Roman.woff2') format('woff2'),
        url('SourceSans3-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

