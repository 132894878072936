:root{
 
    --bg-color:#00bffe;
    --body_color:#eeeeee;
  
  }
.chatimagenew{
  max-height: 50px;
  max-width: 50px;
  width: 100%;
  height: 100%;
}
.header-top{
    background-color:var(--bg-color);
    padding: 10px;
    color:#fff;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 20;
}
.bi.bi-list{
    color: #f44236;
}
.menuicon .bi{
    font-size: 23px;
}
.bi-bell-fill{
    font-size: 24px;
}
.headericon .bi{
    font-size:18px;
}
.refresh.btn{
  padding: 5px;
  font-size: 16px;
  font-weight: 600;

}
.bg-change-new{
  background: #4caf50!important;
}
.word_wrap_header{
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 160px; 
  height: 1.2em; 
  margin-left: 12px;
  white-space: nowrap;

}
.refresh {
    padding: 1px;
    border-radius: 9px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(245, 91, 3);
    background: linear-gradient(0deg, rgb(111 50 14) 0%, rgb(245, 91, 3) 87%);
    width: 84%;
    font-size: 13px;
    margin: auto;
    color: #fff;
   
  }
  .refresh a{
    color: #fff;
    }
    .points{
        font-size:13px;
    }


    .badge{
        margin: 10px;
        padding: 1px 3px;
        background: red;
        border-radius: 50%;
        position: absolute;
        left: 25px;
        top: 0px;
        animation: badge_bubble 2s ease-out infinite;
    }

    span.badge.notibadge {
        left: 90%;
    }

    @keyframes badge_bubble {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.5, 1.5);
        }
        100% {
          transform: scale(1, 1);
        }
      }

