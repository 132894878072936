:root{
  --main-color:#00bffe;
}
.display_none{
  display: none!important;
}
.message_send{
  position: absolute;
  top: 7px;
  left: 8px;
}
.bi.bi-send{
  position: absolute;
  top: 7px;
  left: 8px;
}
.spinner-border{
  position: absolute;
  top: -5px;
  z-index: 9;
}
.btn.btn-close-custum{
  padding-top: 69px;
  display: flex;
  justify-content: flex-end;
  width: 65%;
  color: #fff;
  font-size: 27px;

}
.chatdesign{
  justify-content: space-between;
  background: var(--main-color);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.5);
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 8px;
  z-index: 24;
}
.voicechange{
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  left: 7px;
  padding: 1px 0px;
  position: fixed;
  right: 0;
  text-align: center;
  bottom: 11px;
  width: 75%;
  z-index: 999;
}
.block-red{
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: white;
}
.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  width: 30px;
  display: flex;
  align-items: center;
  transition: all .2s ease-in;
  -webkit-tap-highlight-color: transparent;
  /* height: 30px; */
  justify-content: center;
}
/* .bg-mic{
  height: 100vh;
  width: 600px;
  background: #00000061;
  display: inline-block;
  position: fixed;
  z-index: 25;
  top: 0px;
} */
.mic{
  height: 60px;
  position: absolute;
  top: 57%;
  left: 43%;
}
.sendmessage{
  position: absolute;
  top: 2px;
  left: 2px;
}
.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  width: 40px;
  display: flex;
  align-items: center;
  transition: all .2s ease-in;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  top: 8px!important;
  z-index: 1;
}
.audio-recorder.recording {
  right: 179px;
}
.audio-recorder.recording .audio-recorder-mic {
  display: none;
}

.audio-recorder-options~.audio-recorder-options {
  padding: 12px 12px 12px 6px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: -45px;
  color: #fff;
  display: none;
}

.audio-recorder .audio-recorder-mic {
  border-radius: 20px;
  background: green;
}
/* .recording {
  width: 100%!important;
  margin-bottom: 10px;
} */
.Stop_record{
  width: 200px;
  margin: auto;
  background:red;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
}
.inputchat{
  width:85%;
}
.headerchat{
  background-color: var(--main-color);
  padding: 5px 0px;
  position: fixed;
  width: 100%;
  z-index: 11;
}
.homecontainer h6{
margin-bottom: 0px;
line-height:1.5;
}
.headerplaygames{
  width:98%;
}
.countdown{
  text-align:center;
  line-height: 1.5;
}
.countdown p{
  font-size: 11px;
  color: #fff;
  margin-bottom: 0px;
  line-height: 1.3;
}
.timer{
  line-height: .9;
  font-size:12px;
}
.logoheader{
  width:50px;
}
.chatname h2{
  font-size: 16px;
  margin-bottom: 0px;
}
.buttonsend{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009d20;
  border-radius: 50%;
  font-size: 21px;
  position: relative;
}
.headericonarrow .bi{
  font-size:25px;
}
.arrowlink,
.arrowlink:hover{
  color:#fff;
}
.inputchat input:hover,
.inputchat input:focus,
.inputchat input:focus-visible{
  box-shadow: none; 
  outline: none;
}

.inputchat input::placeholder{
  font-size:12px;
  color:#00000091;
}
.logo_babaji{
  width: 90px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  img{
    width: 100%;
  }
}
div.popupnew{
  min-height:100%;
  height:100%;
  background-image:url(http://theartmad.com/wp-content/uploads/Dark-Grey-Texture-Wallpaper-5.jpg);
  background-size:cover;
  background-position:top center;
  font-family:helvetica neue, helvetica, arial, sans-serif;
  font-weight:200;
  &.modal-active {
    overflow: hidden;
  }
}
.nev_five{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0px;
  margin: auto;
  left: 0px;
  transform: scale(0);
  z-index: 999;
  background: #000000bf;
  
  &.one {
    transform:scaleY(.01) scaleX(0);
    animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    .modal-background {
      .modal {
        transform:scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &.out {
      transform:scale(1);
      animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal-background {
        .modal {
          animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  }
  &.two {
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        opacity:0;
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      animation: quickScaleDown 0s .5s linear forwards;
      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal {
           animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }
  &.three {
    z-index:0;
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.6);
      .modal {
        animation: moveUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      z-index:1;
      animation: slideUpLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      .modal-background {
        .modal {
          animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        animation: slideDownLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }
  &.four {
    z-index:0;
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.7);
      .modal {
        animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      z-index:1;
      animation:blowUpContent .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      .modal-background {
        .modal {
          animation: blowUpModalTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        animation: blowUpContentTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }
  &.five {
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        transform:translateX(-1500px);
        animation: roadRunnerIn .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &.out {
      animation: quickScaleDown 0s .5s linear forwards;
      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal {
          animation: roadRunnerOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  }
  &.six {
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        background-color:transparent;
        animation: modalFadeIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        h2,p {
          opacity:0;
          position:relative;
          animation: modalContentFadeIn .5s 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
        .modal-svg {
          rect {
            animation: sketchIn .5s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
        }
      }
    }
    &.out {
      animation: quickScaleDown 0s .5s linear forwards;
      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal {
          animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          h2,p {
            animation: modalContentFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
          .modal-svg {
            rect {
              animation: sketchOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
          }
        }
      }
    }
  }
  &.seven {
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        height:75px;
        width:75px;
        border-radius:75px;
        overflow:hidden;
        animation: bondJamesBond 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        h2,p {
          opacity:0;
          position:relative;
          animation: modalContentFadeIn .5s 1.4s linear forwards;
        }
      }
    }
    &.out {
      animation: slowFade .5s 1.5s linear forwards;
      .modal-background {
        background-color:rgba(0,0,0,.7);
        animation: fadeToRed 2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .modal {
          border-radius:3px;
          height:162px;
          width:227px;
          animation: killShot 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          h2,p {
            animation:modalContentFadeOut .5s .5 cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
        }
      }
    }
  }
 .updateversion{
  font-size: 20px;
  color:#f44336;
 }
 .updateversionsec{
  font-size: 18px;
 }
  .modal-background {
    display:table-cell;
    background:rgba(0,0,0,.8);
    text-align:center;
    vertical-align:middle;
    .modal {
      background: #fff;
      border-radius: 5px;
      font-weight: 300;
      position: relative;
      padding: 20px;
      width: 345px;
      display: flex;
      overflow: visible;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 64px;
      h2 {
        font-size:25px;
        line-height:25px;
        margin-bottom:15px;
      }
      p {
        font-size:18px;
        line-height:22px;
      }
      .modal-svg {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        border-radius:3px;
        rect {
          stroke: #fff;
          stroke-width: 2px;
          stroke-dasharray: 778;
          stroke-dashoffset: 778;
        }
      }
    }
  }
}

.content {
  min-height:100%;
  height:100%;
  background:white;
  position:relative;
  z-index:0;
  color: #000;
  h1 {
    padding:75px 0 30px 0;
    text-align:center;
    font-size:30px;
    line-height:30px;
  }
  .buttons {
    max-width:800px;
    margin:0 auto;
    padding:0;
    text-align:center;
    .button {
      display:inline-block;
      text-align:center;
      padding:10px 15px;
      margin:10px;
      background:red;
      font-size:18px;
      background-color:#efefef;
      border-radius:3px;
      box-shadow:0 1px 2px rgba(0,0,0,.3);
      cursor:pointer;
      &:hover {
        color:white;
        background:#009bd5;
      }
    }
  } 
}

@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}

@keyframes fadeIn {
  0% {
    background:rgba(0,0,0,.0);
  }
  100% {
    background:rgba(0,0,0,.7);
  }
}

@keyframes fadeOut {
  0% {
    background:rgba(0,0,0,.7);
  }
  100% {
    background:rgba(0,0,0,.0);
  }
}

@keyframes scaleUp {
  0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
}

@keyframes scaleDown {
  0% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
}

@keyframes scaleBack {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(.85);
  }
}

@keyframes scaleForward {
  0% {
    transform:scale(.85);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes quickScaleDown {
  0% {
    transform:scale(1);
  }
  99.9% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}

@keyframes slideUpLarge {
  0% {
    transform:translateY(0%);
  }
  100% {
    transform:translateY(-100%);
  }
}

@keyframes slideDownLarge {
  0% {
    transform:translateY(-100%);
  }
  100% {
    transform:translateY(0%);
  }
}

@keyframes moveUp {
  0% {
    transform:translateY(150px);
  }
  100% {
    transform:translateY(0);
  }
}

@keyframes moveDown {
  0% {
    transform:translateY(0px);
  }
  100% {
    transform:translateY(150px);
  }
}

@keyframes blowUpContent {
  0% {
    transform:scale(1);
    opacity:1;
  }
  99.9% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(0);
  }
}

@keyframes blowUpContentTwo {
  0% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
}

@keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform:scale(1);
    opacity:1;
  }
  100% {
    transform:scale(0);
    opacity:0;
  }
}

@keyframes roadRunnerIn {
  0% {
    transform:translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform:translateX(30px) skewX(0deg) scaleX(.9);
  }
  100% {
    transform:translateX(0px) skewX(0deg) scaleX(1);
  }
}

@keyframes roadRunnerOut {
  0% {
    transform:translateX(0px) skewX(0deg) scaleX(1);
  }
  30% {
    transform:translateX(-30px) skewX(-5deg) scaleX(.9);
  }
  100% {
    transform:translateX(1500px) skewX(30deg) scaleX(1.3);
  }
}

@keyframes sketchIn {
	0% {
		stroke-dashoffset: 778;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes sketchOut {
	0% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: 778;
	}
}

@keyframes modalFadeIn {
  0% {
    background-color:transparent;
  }
  100% {
    background-color:white;
  }
}

@keyframes modalFadeOut {
  0% {
    background-color:white;
  }
  100% {
    background-color:transparent;
  }
}

@keyframes modalContentFadeIn {
  0% {
    opacity:0;
    top:-20px;
  }
  100% {
    opacity:1;
    top:0;
  }
}

@keyframes modalContentFadeOut {
  0% {
    opacity:1;
    top:0px;
  }
  100% {
    opacity:0;
    top:-20px;
  }
}

@keyframes bondJamesBond {
  0% {
    transform:translateX(1000px);
  }
  80% {
    transform:translateX(0px);
    border-radius:75px;
    height:75px;
    width:75px;
  }
  90% {
    border-radius:3px;
    height:182px;
    width:247px;
  }
  100% {
    border-radius:3px;
    height:162px;
    width:227px;
  }
}

@keyframes killShot {
  0% {
    transform:translateY(0) rotate(0deg);
    opacity:1;
  }
  100% {
    transform:translateY(300px) rotate(45deg);
    opacity:0;
  }
}

@keyframes fadeToRed {
  0% {
    background-color:rgba(black,.6);
  }
  100% {
    background-color:rgba(red,.8);
  }
}

@keyframes slowFade {
  0% {
    opacity:1;
  }
  99.9% {
    opacity:0;
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
#five{
  position: fixed;
  top: 85px;
  background: red;
}
.new_five{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
  border-radius: 10px;

}

.btnnew  {
  color: white;
  padding: .75rem 2.5rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2rem;
}
.btn-beginner{
  background-color: #4CAF50;
}
.btn-beginner:hover {
  background-color: #60c365;
}

.btn-intermediate {
  background: linear-gradient(to right, #FF416C, #FF4B2B);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.btn-intermediate:hover {
  transform: scale(1.1);
}

.btnnew.btn-masterful {
  background-color: #00b7ff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  width: 175px;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.btn-masterful .icon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 24px;
}
.btn-masterful .btn-txt{
  padding-left: .5rem;
}
.btn-masterful:hover {
  background-color: #018dc5;
}

.btn-masterful:hover .icon {
  animation: rotate 2s linear infinite;
}


.attribute{
  position: absolute;
  bottom: 10px;
}
.changeBG {
  background: #e69a2a !important;
}

.green-row td {
  background-color: green !important;
  color: white !important;
}

.changeBG.active{
  background: #4caf50 !important;
}

.color-cls {
  background: #be4a07 !important;
}
.tablemargin{
  margin-bottom: 7rem !important;
}