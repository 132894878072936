body {
    font-family: 'Arial', sans-serif;
    text-align: center;
    margin: 20px;
}

h1 {
    color: #3498db;
}

p {
    color: #2c3e50;
}