:root{
    --background: #00bffe;
        --bg-color:#00bffe;
        --bg-color3:#f9f9f9;

}

.installapplication{
    background-color: var(--background);
    padding:5px;
    
  }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: auto!important;
    background: #212020!important;
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-header{
    border-bottom:1px solid #ccc;
padding:5px 10px;
  }
  .offcanvas-header .btn-close{
  color:#fff;
  }
  .cancelbtn{
    color:#0075ff
  }
  .offcanvas {
    background-color: #0c0a0a!important;
}
  .offcanvas-title.h5{
    font-size: 16px;
  }
  .list-style-none
  {
    list-style: none;
    padding-left: 0px;
  }
  .arrowcolor{
    color: #0075ff;
  }
  .list-style-none li{
    margin: 10px 0px;
  }
  .list-style-none li .bi{
   font-size: 20px;
  }
  .list-style-none li span{
    margin-left: 12px;

  }
  .content-pwa{
    color: #d2d2d2;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4;
  }
.Loginpage{
  width: 100vw;
  background: linear-gradient(175deg, rgba(0,191,255,1) 0%, rgba(170,170,170,1) 85%);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.otp_login{
  background-color: var(--background);
}
.logo {
  width: 75px;
  background: rgb(255,139,2);
  background: linear-gradient(175deg, rgba(255,139,2,1) 0%, rgba(255,184,48,1) 85%);
  background: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 3;
}
.logo img{
    width: 100%;
}
.icontable small{
    font-size:15px;
}

.logobgshape {
    border-bottom-left-radius: 80px;
    position: relative;
    top: 38px;
}
.login-form{
  width: 90%;
  padding: 0px 0px;
  height: auto;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  position: relative;
  z-index: 1;
  margin: auto;
  border-radius: 6px;

}
.btn_custum .spinner-border {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 24%;
  right: 88px;
}
.loginform{
    width:100%;
    position:relative;
}
.form-login-design{
    padding: 19px 9px;
}
.form-custom{
    width:100%;
    padding: 10px 40px;
    box-shadow: 1px -2px 5px 0px rgb(207 207 207 / 75%);
    border-radius: 37px!important;
    appearance: none;
}
.form-custom::placeholder{
    padding:0px 0px; 
}
.valid-button {
  background-color: #720d5d;
  

  
}
.Loginbtn{
    box-shadow: none;
    border:none;
    outline:none;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    width:100%;
    text-align: center;
    background: var(--bg-color) ;
    padding:8px 10px;
    color:#fff;
    height: 100%;

}
.bg-white{
  background-color: #fff!important;
}
.phone{
    position:absolute;
    left:15px;
    top:10px;
}
a{
    text-decoration: none;
}
.username{
    padding: 5px 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
}
.username:focus{
  box-shadow:none;
  outline:none;
}
.username input::placeholder{
 opacity: 0.5;
}
.pwa_bbtn_color{

  background: rgb(255 ,139, 2) !important;
}
.pwabtn{
  margin: auto;
  padding: 6px 5px;
  font-size: 14px;
}
.pwabtn:active, 
.pwabtn:focus,
.pwabtn:focus-visible{
  background: rgb(255, 139, 2);
  margin: auto;
  padding: 2px 5px;
  font-size: 14px;
}
/* 
.spinner-wrapper{
    width: 100%;
    height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: hidden;
    left: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   */
  /* .mesh-loader {
    overflow: hidden;
    height: inherit;
    width: inherit;
  }
  .mesh-loader .circle {
    width: 30px;
    height: 30px;
    position: absolute;
    background: linear-gradient(rgb(114 13 93), rgb(114 13 93));
    border-radius: 50%;
    margin: -15px;
    -webkit-animation: mesh 3s ease-in-out infinite -1.5s;
    animation: mesh 3s ease-in-out infinite -1.5s;
  }
  
  .mesh-loader > div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .mesh-loader > div {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .mesh-loader > div:last-child {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  @-webkit-keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.1% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.1% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  } */